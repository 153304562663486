import React, { useEffect, useState } from 'react'
import { Route, Switch } from "react-router-dom";
import SpireAccount from '../components/Accounts/SpireAccount';
import Header from '../components/common/Header'
import Configurations from '../components/Configurations/Configurations';
import SpireAccounts from '../components/SpireAccounts/SpireAccounts';
import ShopifyAccounts from '../components/Accounts/ShopifyAccounts'
import ShopifyAccount from '../components/Accounts/ShopifyAccount'
import HubspotAccounts from '../components/Accounts/HubspotAccounts';
import SyncHistory from '../components/SyncHistory/SyncHistory';
import AuthStore from '../stores/AuthStore';
import HubspotAccount from '../components/Accounts/HubspotAccount';
import Connections from '../components/Configurations/Connections';
import Connection from '../components/Configurations/Connection';
import Configuration from '../components/Configurations/Configuration';
import Mapping from '../components/Mapping/Mapping';
import CreateAdmin from '../components/Create-Admin/Create-Admin';
import ListClient from '../components/create-client/List-client';
import AddClient from '../components/create-client/Add-Client';
import ListAdmins from '../components/Create-Admin/List-Admins';
import Warehouses from '../components/Configurations/Warehouses';
import Warehouse from '../components/Configurations/Warehouse';
import ProductCodes from '../components/Configurations/ProductCodes';
import ProductCode from '../components/Configurations/ProductCode';
import ShopifyProductCategories from '../components/Configurations/ShopifyProductCategories';
import ShopifyProductCategory from '../components/Configurations/ShopifyProductCategory';
import SalesTaxes from '../components/Configurations/SalesTaxes';
import SalesTax from '../components/Configurations/SalesTax';

function Home(props) {

    const [isLoggedin, setIsLoggedIn] = useState(false);

    useEffect(() => {
        AuthStore.checkLoginStatus().catch(() => {
            props.history.push('/login');
        }).then(() => {
            setIsLoggedIn(true);
        })
    }, [props])

    if (!isLoggedin) {
        return <div></div>
    }

    return <React.Fragment>
        <Header />
        <div className="main-body">
            <div className="container">
                <Switch>
                    <Route path="/configurations" exact component={Configurations} />
                    <Route path="/spire-account/add" exact component={SpireAccount} />
                    <Route path="/spire-account/edit/:id" exact component={SpireAccount} />
                    <Route path="/spire-accounts" exact component={SpireAccounts} />
                    <Route path="/shopify-accounts" exact component={ShopifyAccounts} />
                    <Route path="/hubspot-accounts" exact component={HubspotAccounts} />
                    <Route path="/hubspot-account/add" exact component={HubspotAccount} />
                    <Route path="/hubspot-account/edit/:id" exact component={HubspotAccount} />
                    <Route path="/shopify-account/add" exact component={ShopifyAccount} />
                    <Route path="/shopify-account/edit/:id" exact component={ShopifyAccount} />
                    <Route path="/connections" exact component={Connections} />
                    <Route path="/mappings" exact component={Mapping} />
                    <Route path="/connection/add" exact component={Connection} />
                    <Route path="/connection/edit/:id" exact component={Connection} />
                    <Route path="/configuration/add" exact component={Configuration} />
                    <Route path="/configuration/edit/:id" exact component={Configuration} />
                    <Route path="/sync-history" exact component={SyncHistory} />
                    <Route path="/" exact component={SyncHistory} />
                    <Route path="/list-admin" component={ListAdmins} />
                    <Route path="/create-admin" component={CreateAdmin} />
                    <Route path="/list-client" component={ListClient} />
                    <Route path="/edit-client/:id" component={AddClient} />
                    <Route path="/create-client" component={AddClient} />
                    <Route path="/edit-admin/:id" component={CreateAdmin} />
                    <Route path="/warehouses" exact component={Warehouses} />
                    <Route path="/warehouse/add" exact component={Warehouse} />
                    <Route path="/warehouse/edit/:id" exact component={Warehouse} />
                    <Route path="/productcodes" exact component={ProductCodes} />
                    <Route path="/productcode/add" exact component={ProductCode} />
                    <Route path="/productcode/edit/:id" exact component={ProductCode} />
                    <Route path="/shopifyproductcategories" exact component={ShopifyProductCategories} />
                    <Route path="/shopifyproductcategory/add" exact component={ShopifyProductCategory} />
                    <Route path="/shopifyproductcategory/edit/:id" exact component={ShopifyProductCategory} />
                    <Route path="/salestaxes" exact component={SalesTaxes} />
                    <Route path="/salestax/add" exact component={SalesTax} />
                    <Route path="/salestax/edit/:id" exact component={SalesTax} />

                </Switch>
            </div>
        </div>
    </React.Fragment>
}

export default Home;


//