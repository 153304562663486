import { makeObservable, observable } from "mobx";

class GlobalStore {
    pageName = "";
    constructor(){
        makeObservable(this,{
            pageName:observable
        })
    }
}

export default new GlobalStore();