import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";
import AuthStore from "../../stores/AuthStore";



function Warehouse(props) {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const form = useRef(null);
  const [client_id, setClientId] = useState();



  useEffect(() => {
    ConfigurationStore.getClients();
    let id = props.match.params.id;
    if (id) {
      ConfigurationStore.getWarehouse(id).then((result) => {
        Object.keys(result).map(key => {
          console.log(`result on warehouse edit: ${JSON.stringify(result)}`)
          if (document.getElementsByName(key).length > 0) {
            document.getElementsByName(key)[0].value = result[key]
          }
          if(key === 'client_id'){
            setClientId(result[key])
          }
         
        })
      })
      setEditMode(true);
    }
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(form.current);
    let data = {};
   // let userCompany = localStorage.getItem('userCompany')
    for (var key of formData.keys()) {
      data[key] = formData.get(key);
    }

   
    if (editMode) {
      let warehouse_id = props.match.params.id;
      delete data.client_id;
      ConfigurationStore.updateWarehouse(warehouse_id, data)
        .then(() => {
          setLoading(false);
          props.history.push("/warehouses");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    else {
      ConfigurationStore.createWarehouse(data)
        .then(() => {
          setLoading(false);
          props.history.push("/warehouses");
        })
        .catch((err) => {
          setLoading(false);
        });
    }


  };

  let { clients } = ConfigurationStore;
  return (
    <div className="add-element">
      <div className="box">
        <h2>{editMode ? 'Edit' : 'Add'} Warehouse</h2>
        <div className="form-block">
          <form ref={form} onSubmit={(e) => onSubmit(e)}>
            <div className="input-box">
              <label>Name</label>
              <input className="form-control" name="name" />
            </div>
            <div className="input-box">
              <label>Warehouse Code</label>
              <input className="form-control" name="whse" />
            </div>
            <div className="input-box">
              <label>Shopify Location ID</label>
              <input className="form-control" name="shopify_loc_id" />
            </div>
           
             <div className="input-box">
                <label>Select Client</label>
                  <select
                    name="client_id"
                    className="form-control"
                    disabled= {editMode}
                    value={client_id}
                  >
                  <option value="">Select client</option>
                    {clients &&
                      clients.map((c) => {
                      return (
                      <option value={c._id}>
                        {c.name}
                      </option>
                      );
                      })}
                  </select>
            </div>
            <div className="btn-box">
              <button disabled={loading} htmlType="submit" type="submit" className="primarybtn create-cnt-btn">
                {loading ? `Please wait...` : `${editMode ? 'Update' : 'Create'} Warehouse`}
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
}

export default observer(Warehouse);
