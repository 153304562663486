import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import AuthStore from '../../stores/AuthStore';

function Login(props) {

    const form = useRef(null);
    const [error, setError] = useState(null);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isEmailCorrect, setIsEmailCorrect] = useState(false);
    const [selectableOptions, setSelectableOptions] = useState([]);
    useEffect(() => {

        AuthStore.checkLoginStatus().then(() => {
            props.history.push('/sync-history');
        })
    }, [])



    const onSubmit = e => {
        e.preventDefault();
        setIsLoggingIn(true);
        const formData = new FormData(form.current)
        let companies
        if (formData.get('company') === '') {
            companies = ["super"]
        } else {
            companies = [formData.get('company')]
        }

        let email = localStorage.getItem('userEmail');
        //console.log(email)
        let password = formData.get('password');
        AuthStore.doLogin({ email, password, companies }).then(() => {
            setIsLoggingIn(false);
            props.history.push('/sync-history');
        }).catch((e) => {
            setIsLoggingIn(false);
            setError(e)
        })
    };

    const checkEmail = e => {
        e.preventDefault()
        let email = document.getElementById('email').value

        AuthStore.checkEmail({ email }).then((response) => {
            try {
                if (response.length > 0) {

                    document.getElementById('select-option').classList.remove('hidden');
                    document.getElementById('password').classList.remove('hidden');
                    document.getElementById('login-btn').classList.remove('hidden');
                    document.getElementById('btn-reset').classList.remove('hidden');
                    document.getElementById('btn-next').classList.add('hidden');
                    setIsEmailCorrect(true)
                    setSelectableOptions(response.map(({ _id: id, name: label, code: value }) => ({
                        id, 
                        label,
                        value
                    })))
                }
            } catch {
                alert('Wrong Email')
                window.location.reload();
            }

        });
    }

    const pageRelode = e =>{
        window.location.reload();
    }
    
    return (
        <div className="base-sign">
            <div className="form-block">
                <div className="logo-block">
                    <h1>Spire-Hubspot</h1>
                </div>
                <div className="block">
                    <div className="heading-text">
                        <h2>Sign in</h2>
                        <p>Login as admin to make changes to your configurations</p>
                    </div>
                    <div className="base-form">
                        <form autocomplete="off" ref={form} onSubmit={e => onSubmit(e)}>
                            <div className="input-box">
                                <label>Email</label>
                                <input name="email" disabled={isEmailCorrect} type="email" placeholder="Enter your email" className="form-control" id='email' />
                            </div>
                            <div className="input-box hidden" id='password'>
                                <label>Password</label>
                                <input name="password" type="password" placeholder="Enter your password" className="form-control" />
                            </div>
                            <div className="input-box hidden" id='select-option'>
                                <label>Select the company</label>

                                <Select
                                    name='company'
                                    placeholder="Select Company"
                                    options={selectableOptions}
                                // isMulti
                                />
                            </div>
                            {
                                error ?
                                    <p className="err">{error}</p> : null
                            }
                            <div className='btn-container'>
                            <div className="btn-field hidden" id='login-btn'>
                                <button disabled={isLoggingIn} className="primarybtn">
                                    {
                                        isLoggingIn ?
                                            `Please wait...` :
                                            `Login`
                                    }
                                </button>
                            </div>
                            <div className="btn-field hidden" id='btn-reset'>
                                    <button onClick={pageRelode} className="primarybtn">
                                        RESET
                                    </button>
                                    </div>
                                    </div>

                            <div className="btn-field" id='btn-next'>
                                <button onClick={checkEmail} className="primarybtn">
                                    NEXT
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
