import axios from "axios";

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

http.interceptors.request.use(
  async (config) => {
    config.headers["Content-Type"] = "application/json";
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        //alert('Logout');
        return Promise.reject(error);
      }

     //console.log("ERROR ON API")
    }
    return Promise.reject(error);
  }
);
