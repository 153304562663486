import { http } from "../../services/http";

class CompanyStore {

    addCompany = (values) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/account/client/add`, { ...values }).then(result => {
            resolve(result);
        }).catch(err => {
            reject(err.response.data || "Error Occured")
        })
    })

    updateCompanyName = (values) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/companies/name/update`, { ...values }).then(result => {
            resolve(result);
        }).catch(err => {
            reject(err.response.data || "Error Occured")
        })
    })

    createAdmin = (values) => new Promise((resolve, reject) => {
        http.post(`/v1/auth/signup`, { ...values }).then(result => {
            resolve("Success");
        }).catch(err => {
            reject(err.response.data || "Error Occured")
        })
    })

    updateAdmin = (values) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/user/update`, { ...values }).then(result => {
            resolve("Success");
        }).catch(err => {
            reject(err.response.data || "Error Occured")
        })
    })

    updateMapping = (code, data) => new Promise((resolve, reject) => {
        http.post(`v1/admin/companies/mapping/update/${code}`, { ...data }).then(result => {
            resolve("Success");
        }).catch(err => {
            reject(err.response.data || "Error Occured")
        })
    })

    

}

export default new CompanyStore();