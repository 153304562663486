import React, {  useEffect, useState } from 'react'
import AuthStore from '../../stores/AuthStore';
import { Link } from 'react-router-dom'
import GlobalStore from "../../stores/GlobalStore";


const ListClient = (props) => {
    const [selectableOptions, setSelectableOptions] = useState([]);

    // handle onChange event of the dropdown
    useEffect(() => {
        GlobalStore.pageName = 'create';
        AuthStore.getAllClients().then((response) => {
            setSelectableOptions(response.map(({ _id: id, name: label, code: value , isPrdCodeForInvToCrm}) => 
                {
               return {
                id,
                label,
                value,
                isPrdCodeForInvToCrm
            }}))
        });

        AuthStore.checkLoginStatus().then(() => {
            props.history.push('/list-client');
        })
    }, [])


    const showDataInTable = () => {
        return selectableOptions.map(
            (info, index) => {
                return (
                    <tr>
                        <td>{index + 1}</td>
                        <td>{info.label}</td>
                        <td>{info.value}</td>
                        <td>{info.isPrdCodeForInvToCrm? 'Yes': 'No'}</td>
                        <td><Link to={"/edit-client/" + info.value}><button className='list-client-btn-edit'>Edit</button></Link></td>
                        
                    </tr>
                )
            })
    }

    return (
        <React.Fragment>
            {/* <Header></Header> */}
            <div className="table-info-list-client table-info">
                <table border="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>S. No</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>isPrdCodeForInvToCrm</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody className='list-client-tbody'>
                        {showDataInTable()}
                    </tbody>
                </table>
            </div>

            <Link to={"/create-client"}><button className='list-client-btn-add'>+ Add Client</button></Link>

        </React.Fragment>
    )
}

export default ListClient;