import React, { useEffect, useState } from 'react'
import S2H from './S2H';
import H2S from './H2S';
import ConfigurationStore from '../../stores/ConfigurationStore';
import { observer } from 'mobx-react';
import GlobalStore from '../../stores/GlobalStore';
import {Link} from 'react-router-dom';
import Sp2Sh from './Sp2Sh';
function Configurations() {

    const [ activeTab, setActiveTab ]=useState('1');

    useEffect(()=>{
        ConfigurationStore.getConfigurations();
        GlobalStore.pageName = 'configurations';
    },[])

    const toggleTab = tab =>{
        if(activeTab!== tab) setActiveTab(tab)
    }

    let {configurations=[]} = ConfigurationStore;
    return (
        <div className="element-info">
            <div className="tab-list">
                <ul>
                    <li className={activeTab==='1'?'active':''} onClick={()=>{toggleTab('1')}}>Spire → Hubspot</li>
                    <li className={activeTab==='2'?'active':''} onClick={()=>{toggleTab('2')}}>Hubspot → Spire</li>
		    <li className={activeTab==='3'?'active':''} onClick={()=>{toggleTab('3')}}>Spire → Shopify</li>
                </ul>
            </div>
            <div className="tab-content">
                {
                    activeTab==='1' &&(
                        <S2H list={configurations.filter(cf=>cf.configType === 'sp2hs')}/>
                    )
                }
                {
                    activeTab==='2' &&(
                        <H2S list={configurations.filter(cf=>cf.configType === 'hs2sp')}/>
                    )
                }
		{
                    activeTab==='3' &&(
                        <Sp2Sh list={configurations.filter(cf=>cf.configType === 'sp2sh')}/>
                    )
                }
            </div>

            <div className="bottom-button">
                <Link to="/configuration/add"><button className="primarybtn add-btn">+ Add Configurations</button></Link>
            </div>
        </div>
    )
}

export default observer(Configurations);
