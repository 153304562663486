import React, { useEffect, useRef, useState } from 'react'

import AuthStore from '../../stores/AuthStore';
import Select from 'react-select'
import Header from '../common/Header';
import CompanyStore from './CompanyStore';
import ConfigurationStore from '../../stores/ConfigurationStore';

const CreateAdmin = (props) => {

    const formUser = useRef(null);
    const [selectableOptions, setSelectableOptions] = useState([], () => { });
    const [selectedValue, setSelectedValue] = useState([]);
    const [editMode, setEditMode] = useState(false)
    const [admin, setAdmin] = useState({})
    const [adminCompany, setAdminCompany] = useState([])


    let companiesList = []



    useEffect(() => {
        let id = props.match.params.id
        if (id) {
            setEditMode(true)
        }

        AuthStore.getAllClients().then((response) => {
            for (let company of response) {
                // console.log('----->', company)
                companiesList.push(company)
            }

            setSelectableOptions(response.map(({ _id: id, name: label, code: value }) => ({
                id,
                label,
                value
            })))
        });

        ConfigurationStore.getConnections();




        if (editMode) {
            // setShowForm(true)
            AuthStore.getUserById(id).then((response) => {
                let userCompanies = []
                setAdmin(response)
                for (let i = 0; i < response.companies.length; i++) {
                    let data = {}
                    data.value = response.companies[i]
                    data.label = getCompanyName(response.companies[i])
                    userCompanies.push(data)
                }
                setAdminCompany(userCompanies)
                // setSelectedValue(response.companies)
            })
        }
    }, [])
        const getCompanyName = (code) => {
            // for (var i = 0; i < selectableOptions.length; i++) {
                // console.log('company list ======>', companiesList)
                for (let company of selectableOptions) {

                if (company.value === code) {
                    var name = company.label
                    return name
                }
            }

        }



    // handle onChange event of the dropdown
    const handleChange = (e) => {
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
        // console.log(selectedValue)
    }

    const onSubmit2 = e => {
        e.preventDefault();
        const formDataUser = new FormData(formUser.current)
        if (!editMode) {
            formDataUser.append('role', 'admin')
            formDataUser.delete('companyName')

            let data = {};
            for (var key of formDataUser.keys()) {
                data[key] = formDataUser.get(key);
            }
            data['companies'] = selectedValue
            CompanyStore.createAdmin(data).then(() => {
                alert('Added successfully')
                window.location.href = '/list-admin'
            }).catch(() => {
                alert('Failed to Add')
                window.location.reload();
            })
        } else {
            // console.log('Companies =====>', admin.companies)
           
            formDataUser.delete('companyName')
            let data = {};
            for (var key of formDataUser.keys()) {
                data[key] = formDataUser.get(key);
            }
            data['id'] = admin._id
            
            if (selectedValue.length === 0) {
                data['companies'] = admin.companies
            } else {
                data['companies'] = selectedValue
            }
            if (data['password'] === '*************') {
                delete data.password
            }
            CompanyStore.updateAdmin(data).then(() => {
                alert('Updated successfully')
                window.location.href = '/list-admin'
            }).catch(() => {
                alert('Failed to Update')
                window.location.reload();
            })

            console.log('final call =====>', data)

        }
    };
    return (
        <React.Fragment>
            <Header></Header>
            
            <div className='form-outliner1'>
                <div className='base-form center'>
                    <h2>{editMode ? 'Edit' : 'Add'} Admin </h2>

                    <form autoComplete="off" ref={formUser} onSubmit={e => onSubmit2(e)}>
                        <div className="input-box">
                            <label>Email</label>
                            <input name='email' className="form-control" type="text"  placeholder="Enter Email" defaultValue={editMode ? admin.email : ''}></input>
                        </div>
                        <div className="input-box">
                            <label>Password</label>
                            <input name='password' className="form-control" type="password" placeholder="Enter password" defaultValue={editMode ? '*************' : ''} minLength={6}></input>
                        </div>
                        <div className="input-box">
                            <label>Select company</label>
                            <Select
                                name='companyName'
                                placeholder="Select Company"
                                defaultValue={adminCompany}
                                key={adminCompany}
                                options={selectableOptions}
                                // clearIndicator
                                onChange={handleChange}
                                isMulti
                                required={true}

                            />
                        </div>
                        <div className="btn-field adminpage-btn">
                            <button className="primarybtn">{editMode ? 'Update' : 'Add'}</button>
                        </div>
                    </form>
                </div>
            </div>

        </React.Fragment>
    )
}

export default CreateAdmin;