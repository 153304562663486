import moment from 'moment';
import React from 'react'
import { withRouter } from 'react-router-dom';
import ConfigurationStore from '../../stores/ConfigurationStore'

function S2H(props) {

    const deleteConfiguration = id => {
        let r = window.confirm('Are you sure to delete this configuration?');
        if(r){
            ConfigurationStore.deleteConfiguration(id);
        }
    }

    const editConfiguration = id => {
        props.history.push(`/configuration/edit/${id}`);
    }

    const getTotalRecords = (log) => {
        let {actions} = log;
        let totalCount = 0;
        if(actions && Array.isArray(actions)){
            actions.forEach(a=>{
                if(a.count && !isNaN(a.count)){
                    totalCount += a.count;
                }
            })
        }
        return totalCount
    }

    return (
        <div className="tab-desc tabcontent-hbs-sp">
        {
            props.list && props.list.map(l=>{
                return <div className="block">
                            <div className="box">
                                <div className="up-text">
                                    <h1>{l.name}</h1>
                                    <p className="stg-text">{l.uid}</p>
                                </div>
                             
                                <div className="bottom-field">
                                    <div className="boxes">
                                        <div className="logo">
                                            <img src="/assets/images/hubspot-logo.svg" alt="" />
                                        </div>
                                        <p>{l.connection && l.connection.hubspot}</p>
                                    </div>
                                    <div className="boxes">
                                        <div className="logo">
                                            <img src="/assets/images/spire-logo.png" alt="" />
                                        </div>
                                        <p>{l.connection && l.connection.spire}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="box rc-box">
                                <ol>
                                    <li>
                                        <span className="label-text">Webhook Subscriptions - </span>
                                        <span className="value-text">{l.webhooks && Array.isArray(l.webhooks) && l.webhooks.join(', ')}</span>
                                    </li>
                                </ol>
                            </div>
                            <div className="box rc-box">
                                <h4>Last 3 webhooks from Hubspot</h4>
                                    <ul>
                                    {
                                        l && l.lastLogs && Array.isArray(l.lastLogs) && l.lastLogs.map(ll=>{
                                            return <li>
                                                        <span className="number-text">{getTotalRecords(ll)}</span>
                                                        <p>Records <span>{moment(ll.createdAt).format('DD-MM-YYYY hh:mm A')}</span></p>
                                                    </li>
                                        })
                                    }
                                    {
                                        l && l.lastLogs && Array.isArray(l.lastLogs) && l.lastLogs.length === 0?
                                        <p>No sync yet</p>:null
                                    }
                                    </ul>
                            </div>
                            <div className="box btn-box">
                                <div className="btn-block">
                                    <button className="primarybtn" onClick={()=>editConfiguration(l._id)}>Edit</button>
                                    <button className="primarybtn">Histories</button>
                                    <button className="primarybtn delete-btn" onClick={()=>deleteConfiguration(l._id)}>Delete</button>
                                </div>
                            </div>
                        </div>
            })
        }
            
            
        </div>
    )
}

export default withRouter(S2H)
