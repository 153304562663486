import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ConfigurationStore from '../../stores/ConfigurationStore';
import GlobalStore from '../../stores/GlobalStore';
import moment from 'moment';
import { Pagination, DatePicker, Select } from 'antd';

const defaultCount = 20;
const { RangePicker } = DatePicker;
const {Option} = Select;

function SyncHistory() {

    const [filter,setFilter] = useState({
        from:moment().subtract(1,'day').toDate(),
        to:moment().toDate(),
        configuration_id:null,
        skip:0
    })

    const [logs,setLogs] = useState([]);
    const [count, setCount] = useState(0);
    const [currentPage,setCurrentPage] = useState(1);

    useEffect(()=>{
        GlobalStore.pageName = 'sync-history';
        ConfigurationStore.getConfigurations();
        ConfigurationStore.getLogs(filter).then(result=>{
            setLogs(result.logs);
            setCount(result.count);
        })
    },[])

    useEffect(()=>{
        ConfigurationStore.getLogs(filter).then(result=>{
            setLogs(result.logs);
            setCount(result.count);
        })
    },[filter])

    const getTotalRecords = (log) => {
        let {actions} = log;
        let totalCount = 0;
        if(actions && Array.isArray(actions)){
            actions.forEach(a=>{
                if(a.count && !isNaN(a.count)){
                    totalCount += a.count;
                }
            })
        }
        return totalCount
    }

    const getAddedRecordCount = log => {
        let {actions} = log;
        let totalCount = 0;
        if(actions && Array.isArray(actions)){
            actions = actions.filter(a=>a.event === 'add');
            actions.forEach(a=>{
                if(a.count && !isNaN(a.count)){
                    totalCount += a.count;
                }
            })
        }
        return totalCount
    }

    const getUpdatedRecordCount = log => {
        let {actions} = log;
        let totalCount = 0;
        if(actions && Array.isArray(actions)){
            actions = actions.filter(a=>a.event === 'update');
            actions.forEach(a=>{
                if(a.count && !isNaN(a.count)){
                    totalCount += a.count;
                }
            })
        }
        return totalCount
    }

    const onChangeFilter = (target,value) => {
        let filterObj = {...filter};
        filterObj[target] = value;
        setFilter(filterObj);
    }

    const onChangeDateFilter = (dates) => {
        let filterObj = {...filter};
        filterObj['from'] = dates[0].toDate();
        filterObj['to'] = dates[1].toDate();
        setFilter(filterObj)
    }

    return <React.Fragment>
        <div className="filter-element">
            <div className="heading-text">
                <h1>Sync History (Logs)</h1>
            </div>
            <div className="form-filter">
                <RangePicker onChange={dates=>onChangeDateFilter(dates)} value={[moment(filter.from),moment(filter.to)]} />
                <Select style={{width:"300px", marginLeft:"10px"}} placeholder="Select configuration" value={filter.configuration_id} onChange={(value)=>onChangeFilter('configuration_id',value)}>
                    <Option value={null}>ALL</Option>
                    {
                        ConfigurationStore.configurations.map(c=>{
                            return <Option value={c._id}>{c.name}</Option>
                        })
                    }
                </Select>
            </div>
        </div>
        <div className="table-info">
            <table border="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Configuration</th>
                        <th>Timestamp</th>
                        <th>Direction</th>
                        <th>Sync target</th>
                        <th>Records Processed</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                {
                    logs && Array.isArray(logs) && logs.map(log=>{
                        return <tr>
                                <td>{log.configuration.name}</td>
                                <td>{moment(log.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                                <td>{log.type === 'sp2hs'?'Spire - Hubspot':log.type === 'hs2sp'?'Hubspot - Spire':null}</td>
                                <td>{log.type === 'sp2hs'?log.configuration.fieldType:null}</td>
                                <td>{getTotalRecords(log)}</td>
                                <td>{getAddedRecordCount(log)}</td>
                                <td>{getUpdatedRecordCount(log)}</td>
                                <td className={log.error && log.error !== ""?'error':'success'}>{log.error && log.error !== ""?'Failed':'Success'}</td>
                            </tr>
                    })
                }
                    
                </tbody>
            </table>
        </div>
        <div style={{marginTop:"20px"}}>
            <Pagination current={currentPage} onChange={(page)=>{onChangeFilter('skip',defaultCount*(page-1));setCurrentPage(page)}} total={count} pageSize={defaultCount} showSizeChanger={false} />
        </div>
    </React.Fragment>
}

export default observer(SyncHistory);
