import { useEffect, useRef, useState } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";

import AuthStore from "../../stores/AuthStore";

function HubspotAccount(props) {

    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const form = useRef(null);
    
    const [client_id, setClientId] = useState(null);

    useEffect(() => {
        ConfigurationStore.getClients();
        let id = props.match.params.id;
        if (id) {
            ConfigurationStore.getHubspotAccount(id).then((result) => {
                Object.keys(result).map(key => {
                    if (document.getElementsByName(key).length > 0) {
                        document.getElementsByName(key)[0].value = result[key] ? result[key] : ''
                    }
                    if(key === 'client_id'){
                        setClientId(result[key])
                     }
                })
            })
            setEditMode(true);
        }

    }, [])

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(form.current);
       
        let data = {};
        for (var key of formData.keys()) {
            data[key] = formData.get(key);
        }
      
        if (editMode) {
            data['client_id'] = client_id;
            ConfigurationStore.updateHubspotAccount(props.match.params.id, data).then(() => {
                setLoading(false);
                props.history.push('/hubspot-accounts');
            }).catch(err => {
                setLoading(false);
            })
        }
        else {
            ConfigurationStore.createHubspotAccount(data).then(() => {
                setLoading(false);
                props.history.push('/hubspot-accounts');
            }).catch(err => {
                setLoading(false);
            })
        }
    }

    let { clients } = ConfigurationStore;
    return (
        <div className="add-element">
            <div className="box">
                <h2>{editMode ? 'Edit' : 'Add'} Hubspot account</h2>
                <div className="form-block">
                    <form ref={form} onSubmit={e => onSubmit(e)}>
                        <div className="input-box">
                            <label>Account name</label>
                            <input required name="name" type="text" className="form-control" />
                        </div>
                        <div className="input-box">
                            <label>Account ID</label>
                            <input required name="account_id" type="text" className="form-control" />
                        </div>
                        <div className="input-box">
                            <label>API Key</label>
                            <input required name="api_key" type="text" className="form-control" />
                        </div>
                        <div className="input-box">
                            <label>Deal Stage</label>
                            <input required name="dealstage" type="text" className="form-control" />
                        </div>
                        <div className="input-box">
                            <label>Deal Pipeline</label>
                            <input required name="pipeline" type="text" className="form-control" />
                        </div>
                        <div className="input-box">
                            <label>Deal Type</label>
                            <input required name="dealtype" type="text" className="form-control" />
                        </div>
                        <div className="input-box">
                            <label>Deal Complete Stage</label>
                            <input required name="dealstagecomplete" type="text" className="form-control" />
                        </div>

                        
                        <div className="input-box">
                         <label>Select Client</label>
                         <select
                          disabled={editMode}
                          name="client_id"
                          className="form-control"
                          value={client_id}
                        >
                        <option value="">Select client</option>
                            {clients &&
                            clients.map((c) => {
                        return (
                        <option value={c._id}>
                            {c.name}
                        </option>
                        );
                        })}
                        </select>
                        </div>



                        <div className="btn-box">
                            <button disabled={loading} htmlType="submit" className="primarybtn">
                                {
                                    loading ? `Please wait...` : editMode ? 'Update' : 'Add'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="box desc-box">
                <p>Duis gravida vehicula dui, non gravida ex tempus in. Aliquam in diam eu urna varius cursus. Duis ut auctor purus, non consequat nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas lorem nibh.</p>
            </div>
        </div>
    )
}

export default HubspotAccount;