import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import GlobalStore from '../../stores/GlobalStore'
import AuthStore from '../../stores/AuthStore';

function Header(props) {
    const [isDropDownDB, setDropDownDB]=useState(false);
    const [hidden, setHidden] = useState(false);
    let {pageName} = GlobalStore;

    useEffect(() => {
       if (localStorage.getItem('userRole') === 'super') {
        setHidden(true)
       }
    }, [])

    return (
        <div className="header-nav">
            <div className="container">
                <div className="lt-nav">
                    <div className="logo-block">
                        <Link to="">Spire{`--><--`}Hubspot</Link>
                    </div>
                    <div className="navigation">
                        <ul>
                            <li className={pageName==='sync-history'?'active':''}><Link to="/sync-history">Sync History</Link></li>
                            <li className={pageName==='configurations'?'active':''}><Link to="/configurations">Configurations</Link></li>
                            <li className={pageName==='spire-accounts'?'active':''}><Link to="/spire-accounts">Spire Accounts</Link></li>
                            <li className={pageName==='shopify-accounts'?'active':''}><Link to="/shopify-accounts">Shopify Accounts</Link></li>
                            <li className={pageName==='hubspot-accounts'?'active':''}><Link to="/hubspot-accounts">Hubspot Accounts</Link></li>
                            <li className={pageName==='connections'?'active':''}><Link to="/connections">Connections</Link></li>
                            <li className={pageName==='mappings'?'active':''}><Link to="/mappings">Mappings</Link></li>
                            <li > <div className='dropdown-header-menu' style={{display: hidden ? 'block' : 'none'}}>Create
                            <div className='menu' >
                                <Link to='/list-client'  className='option1'>Create client</Link >
                                <Link to='/list-admin' className='option2' >Create admin</Link >
                                <Link to='/warehouses' className='option3' >Warehouses</Link >
                                <Link to='/productcodes' className='option4' >Porduct Codes</Link >
                                <Link to='/shopifyproductcategories' className='option5' >Shopify Prod Category</Link >
                                </div>
                            </div>
                            </li>
                            <li className={pageName==='salestaxes'?'active':''}><Link to="/salestaxes">Taxes</Link></li>
                            
                        </ul>
                    </div>
                </div>
                
                <div className="db-block">
                    <button className="dropdown-btn" onClick={()=>{setDropDownDB(true)}}>
                        <div className="db-box">
                            <span>{AuthStore.userEmail && AuthStore.userEmail[0] && AuthStore.userEmail[0].toUpperCase()}</span>
                        </div>
                        <span className="arrow-icon"><img src="/assets/images/dropdown-arrow.svg" alt="" /></span>
                    </button>
                    <div className={isDropDownDB?'dropdown-overlay active':'dropdown-overlay'}onClick={()=>{setDropDownDB(false)}}></div>
                    {
                        isDropDownDB?<div className="dropdown-box">
                        <ul onClick={()=>{setDropDownDB(false)}}>
                            <li>{AuthStore.userEmail}</li>
                            <li className="logout-btn" onClick={()=>props.history.push('/logout')}>Log out</li>
                            {/* <li className='logout-btn' onClick={()=>props.history.push('/create-admin')} style={{display: hidden ? 'block' : 'none'}}>Create Admin</li> */}
                        </ul>
                    </div>:null
                    }
                    
                </div>
                
                
            </div>
        </div>
    )
}

export default withRouter(observer(Header))
