import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect } from 'react'
import ConfigurationStore from '../../stores/ConfigurationStore';
import GlobalStore from '../../stores/GlobalStore';
import {Link} from 'react-router-dom';

function HubspotAccounts(props) {

    useEffect(()=>{
        ConfigurationStore.getHubspotAccounts();
        GlobalStore.pageName = 'hubspot-accounts';
    },[])

    const edit = (id) => {
        props.history.push(`/hubspot-account/edit/${id}`);
    }

    const onDelete = (id) => {
       let r = window.confirm('Are you sure to delete this account?');
       if(r){
           ConfigurationStore.deleteHubspotAccount(id).then(()=>{
                ConfigurationStore.getHubspotAccounts()
           })
       }
    }

    const activateHubspotAccount = (id) => {
        let r = window.open(`https://app.hubspot.com/oauth/authorize?client_id=${process.env.REACT_APP_HUBSPOT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HUBSPOT_REDIRECT_URL}&scope=e-commerce%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read%20crm.objects.line_items.read%20crm.objects.line_items.write%20crm.schemas.line_items.read`,"Connect your hubspot account","width=1000,height=500,left=50,top=50,toolbar=0,status=0,");
        let isTransactionComplete = setInterval(()=>{
            if(r.closed){
                ConfigurationStore.getHubspotAccountAppConnectionStatus(id);
                clearInterval(isTransactionComplete)
            }
        },500)
    }

    let {hubspotAccounts=[]} = ConfigurationStore;
    return <React.Fragment>
        <div className="table-info">
            <table border="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>API Key</th>
                        <th>In-use</th>
                        <th>Client</th>
                        <th>Last used</th>
                        <th>Active Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    hubspotAccounts && hubspotAccounts.map(account=>{
                        return <tr>
                                    <td>{account.name}</td>
                                    <td>XXXXXXXXXX</td>
                                    <td>
                                        <span className={`color-text ${account.in_use?`gr-color`:`rd-color`}`}>{account.in_use?'YES':'NO'}</span>
                                    </td>
                                    <td>{account?.client_data[0]?.code}</td>
                                    <td>{moment(account.createdAt).format('DD/MM/YYYY hh:mm A')}</td>
                                    <td>{!account.isActive?<button className="primarybtn" onClick={()=>{activateHubspotAccount(account._id)}}>Activate</button>:<span style={{color:"green"}}>Active</span>}</td>
                                    <td>
                                        <button className="primarybtn" onClick={()=>edit(account._id)}>Edit</button>
                                        <button disabled={account.in_use} onClick={()=>onDelete(account._id)} className="primarybtn delete-btn">Delete</button>
                                    </td>
                                </tr>
                    })
                }
                    
                </tbody>
            </table>

            <div className="bottom-button">
                <Link to="/hubspot-account/add"><button className="primarybtn add-btn">+ Add Account</button></Link>
            </div>
        </div>
    </React.Fragment>
}

export default observer(HubspotAccounts);
