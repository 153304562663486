import { makeAutoObservable, observable } from "mobx";
import { http } from "../services/http";
import { Modal } from 'antd'

class ConfigurationStore {

    spireAccounts = [];
    hubspotAccounts = [];
    connections = [];
    configurations = [];
    warehouses = [];
    productCodes = [];
    shopifyProductCategories = [];
    clients = [];
    salestaxes = [];

    addSpireAccount = (data) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/account/spire`, data).then(result => {
            resolve();
        }).catch(err => {
            reject();
        })
    })

    updateSpireAccount = (id, data) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/account/spire/${id}`, data).then(result => {
            resolve();
        }).catch(err => {
            reject();
        })
    })

    getSpireAccount = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/account/spire/${id}`).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject();
        })
    })

    getSpireAccounts = () => new Promise((resolve, reject) => {
        let company = localStorage.getItem('userCompany')
        if (company !== 'super') {
            http.get(`/v1/admin/account/spire/client/${company}`).then(result => {
                this.spireAccounts = result.data
            })
        } else {
            http.get(`/v1/admin/account/spire/`).then(result => {
                this.spireAccounts = result.data
            })
        }
    })

    deleteSpireAccount = (id) => new Promise((resolve, reject) => {
        http.delete(`/v1/admin/account/spire/${id}`).then(result => {
            resolve();
        }).catch(err => {
            reject();
        })
    })

    deleteShopifyAccount = (id) => new Promise((resolve, reject) => {
        http.delete(`/v1/admin/account/shopify/${id}`).then(result => {
            resolve();
        }).catch(err => {
            reject();
        })
    })

    getShopifyAccount = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/account/shopify/${id}`).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject();
        })
    })

    updateShopifyAccount = (id,data) => new Promise((resolve,reject)=>{
        http.put(`/v1/admin/account/shopify/${id}`,data).then(result=>{
            resolve();
        }).catch(err=>{
            reject();
        })
    })

    createShopifyAccount = (data) => new Promise((resolve,reject)=>{
        http.post(`/v1/admin/account/shopify`,data).then(result=>{
            resolve();
        }).catch(err=>{
            reject();
        })
    })

    getShopifyAccounts = () => new Promise((resolve,reject)=>{
        let company = localStorage.getItem('userCompany')
        if (company !== 'super') {
            http.get(`/v1/admin/account/shopify/client/${company}`).then(result => {
                this.shopifyAccounts = result.data;
                resolve();
            }).catch(err => {
                reject();
            })
        } else {
            http.get(`/v1/admin/account/shopify`).then(result => {
                this.shopifyAccounts = result.data;
                resolve();
            }).catch(err => {
                reject();
            })
        }
        
    })

    getClients = () => new Promise((resolve, reject) => {
        console.log(`Inside getClients`)
        let company = localStorage.getItem('userCompany')
        if (company === 'super') {
            http.get(`/v1/admin/companies/all`).then(result => {
                this.clients = result.data
            })
        } else {
            http.get(`/v1/admin/companies/get/${company}`).then(result => {
                this.clients = result.data
            })
        }
    })

    getSalesTaxes = () => new Promise((resolve,reject)=>{
        http.get(`/v1/admin/salestaxes`).then(result=>{
            this.salestaxes = result.data;
            resolve()
        })
    })

    getSalesTax = (id) => new Promise((resolve,reject)=>{
        http.get(`/v1/admin/salestaxes/${id}`).then(result=>{
            resolve(result.data);
        })
    })

    createSalesTax = (data) => new Promise((resolve,reject)=>{
        http.post(`/v1/admin/salestaxes`,data).then(result=>{
            resolve();
        })
    })

    updateSalesTax = (id,data) => new Promise((resolve,reject)=>{
        http.put(`/v1/admin/salestaxes/${id}`,data).then(result=>{
            resolve()
        })
    })

    getHubspotAccounts = () => new Promise((resolve, reject) => {
        console.log(`Inside getHubspotAccounts`)
        let company = localStorage.getItem('userCompany')
        if (company === 'super') {
            http.get(`/v1/admin/account/hubspot`).then(result => {
                this.hubspotAccounts = result.data
            })
        } else {
            http.get(`/v1/admin/account/hubspot/client/${company}`).then(result => {
                this.hubspotAccounts = result.data
            })
        }
    })

    createHubspotAccount = (data) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/account/hubspot`, data).then(result => {
            resolve();
        }).catch(err => {
            reject();
        })
    })

    updateHubspotAccount = (id, data) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/account/hubspot/${id}`, data).then(result => {
            resolve();
        }).catch(err => {
            reject();
        })
    })

    getHubspotAccount = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/account/hubspot/${id}`).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject();
        })
    })

    deleteHubspotAccount = (id) => new Promise((resolve, reject) => {
        http.delete(`/v1/admin/account/hubspot/${id}`).then(result => {
            resolve();
        }).catch(err => {
            reject();
        })
    })

    getLogs = (filter) => new Promise((resolve, reject) => {
        let options = {
            params: filter
        }
        http.get(`/v1/admin/logs`, options).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject();
        })
    })

    getConnections = () => new Promise((resolve, reject) => {
       let userCompany=localStorage.getItem('userCompany')
        if (userCompany === 'super') {
        http.get(`/v1/admin/connections`).then(result => {
            this.connections = result.data;
            //console.log(`data conn: ${JSON.stringify(result?.data)}`)
            resolve()
        })
    } else {
        http.get(`/v1/admin/connections/client/${userCompany}`).then(result => {
            this.connections = result.data;
            resolve()
        })
    }
    })

    getConnection = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/connection/${id}`).then(result => {
            resolve(result.data);
        })
    })

    createConnection = (data) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/connection`, data).then(result => {
            resolve();
        })
    })

    updateConnection = (id, data) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/connection/${id}`, data).then(result => {
            resolve()
        })
    })

    deactivateConnection = (id) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/connection/deact/${id}`).then(result => {
            resolve();
        })
    })

    actiavateConnection = (id) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/connection/activate/${id}`).then(result => {
            resolve();
        })
    })

    addConfiguration = (data) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/configuration`, data).then(result => {
            resolve();
        })
    })

    getConfigurations = () => new Promise((resolve, reject) => {
        let userCompany=localStorage.getItem('userCompany')
        if (userCompany === 'super') {
        http.get(`/v1/admin/configurations`).then(result => {
            this.configurations = result.data;
        })
    } else {
        http.get(`/v1/admin/configurations/client/${userCompany}`).then(result => {
            this.configurations = result.data;
        })
    }
    })

    getHubspotAccountAppConnectionStatus = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/hubspot/activestatus/${id}`).then(result => {
            if (result.data) {
                alert('Successfully Activated');
                this.getHubspotAccounts();
            }
            else {
                alert('Couldn\'t be activated');
            }
        })
    })

    getHubspotFields = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/hubspot/${id}/fields`).then(results => {
            resolve(results.data)
        }).catch(err => {

        })
    })

    deleteConfiguration = id => new Promise((resolve, reject) => {
        http.delete(`/v1/admin/configuration/${id}`).then(result => {
            this.getConfigurations();
        })
    })

    getConfiguration = id => new Promise((resolve, reject) => {
        http.get(`/v1/admin/configuration/${id}`).then(result => {
            //console.log(`config retrieved: ${JSON.stringify(result.data)}`)
            resolve(result.data);
        })
    })

    updateConfiguration = (id, data) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/configuration/${id}`, data).then(result => {
            resolve('');
        })
    })

    
    getWarehouses = () => new Promise((resolve, reject) => {
        console.log(`Inside getWarehouses`)
        let userCompany=localStorage.getItem('userCompany')
        if (userCompany === 'super') {
        http.get(`/v1/admin/warehouses`).then(result => {
            this.warehouses = result.data;
            console.log(`data whses: ${JSON.stringify(result?.data)}`)
            resolve()
        })
    } else {
        http.get(`/v1/admin/warehouses/client/${userCompany}`).then(result => {
            this.warehouses = result.data;
            resolve()
        })
    }
    })

    getWarehouse = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/warehouse/${id}`).then(result => {
            resolve(result.data);
        })
    })

    createWarehouse = (data) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/warehouse`, data).then(result => {
            resolve();
        })
    })

    updateWarehouse = (id, data) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/warehouse/${id}`, data).then(result => {
            resolve()
        })
    })
    
    //
    
    getProductCodes = () => new Promise((resolve, reject) => {
        console.log(`Inside getProductCodes`)
        let userCompany=localStorage.getItem('userCompany')
        if (userCompany === 'super') {
        http.get(`/v1/admin/productcodes`).then(result => {
            this.productCodes = result.data;
            console.log(`data whses: ${JSON.stringify(result?.data)}`)
            resolve()
        })
    } else {
        http.get(`/v1/admin/productcodes/client/${userCompany}`).then(result => {
            this.productCodes = result.data;
            resolve()
        })
    }
    })

    getProductCode = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/productcode/${id}`).then(result => {
            resolve(result.data);
        })
    })

    createProductCode = (data) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/productcode`, data).then(result => {
            resolve();
        })
    })

    updateProductCode = (id, data) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/productcode/${id}`, data).then(result => {
            resolve()
        })
    })

    ////
    
    getShopifyProductCategories = () => new Promise((resolve, reject) => {
        console.log(`Inside getShopifyProductCategories`)
        
        http.get(`/v1/admin/shopifyproductcategories`).then(result => {
            this.shopifyProductCategories = result.data;
            console.log(`data shopifyproductcategories: ${JSON.stringify(result?.data)}`)
            resolve()
        })
  
    })

    getShopifyProductCategory = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/shopifyproductcategory/${id}`).then(result => {
            resolve(result.data);
        })
    })

    createShopifyProductCategory = (data) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/shopifyproductcategory`, data).then(result => {
            resolve();
        })
    })

    updateShopifyProductCategory = (id, data) => new Promise((resolve, reject) => {
        http.put(`/v1/admin/shopifyproductcategory/${id}`, data).then(result => {
            resolve()
        })
    })
    
    

    constructor() {
        makeAutoObservable(this, {
            spireAccounts: observable,
            hubspotAccounts: observable,
            connections: observable,
            configurations: observable,
            warehouses: observable,
            productCodes: observable,
            shopifyProductCategories: observable,
            clients: observable
        })
    }
}

export default new ConfigurationStore();