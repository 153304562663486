import { observer } from "mobx-react";
import { useEffect } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";
import GlobalStore from "../../stores/GlobalStore";
import { Link } from "react-router-dom";

function ProductCodes(props) {
  useEffect(() => {
    ConfigurationStore.getProductCodes();
    GlobalStore.pageName = "productcodes";
  }, []);

  const edit = (productCode_id) => {
    props.history.push(`/productcode/edit/${productCode_id}`);
  };

  let { productCodes = [] } = ConfigurationStore;
  // console.log(`warehouses: ${JSON.stringify(productCodes)}`)
  return (
    <div className="table-info">
      <table border="0" cellSpacing="0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Product Code</th>
            <th>Shopify Collection Name</th>
            <th>Sync to CRM</th>
            <th>Client</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {productCodes &&
            productCodes.map((productCode) => {
              const crmSync = productCode.syncToCrm ? "Yes" : "No";
              return (
                <tr>
                  <td>{productCode.name}</td>
                  <td>{productCode.productCode}</td>
                  <td>{productCode.shopifyCollection}</td>
                  <td>{crmSync}</td>
                  <td>{productCode?.client_data[0]?.code}</td>
                  <td>
                    <button
                      className="primarybtn"
                      onClick={() => edit(productCode._id)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <div className="bottom-button">
        <Link to="/productcode/add">
          <button className="primarybtn add-btn">+ Add Product Code</button>
        </Link>
      </div>
    </div>
  );
}

export default observer(ProductCodes);
