import moment from 'moment';
import { React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AuthStore from '../../stores/AuthStore';

const ListAdmins = () => {

    const [selectableOptions, setSelectableOptions] = useState([]);

    useEffect(() => {
        AuthStore.getAllUsers().then((response) => {
            setSelectableOptions(response.map(({ _id: id, companies: clients, email, createdAt }) => ({
                id,
                email,
                clients,
                createdAt
            })))
        });

    }, [])

    const deleteUser = (email) => {
        let confirmation = window.confirm('Are you sure you want to delete ?')
        if (confirmation) {
            AuthStore.deleteUser({email} ).then((response) => {
                if (response === 'success') {
                    alert('Deleted Succesfully')
                    window.location.reload()
                } else {
                    alert('Failed to Delete')
                    window.location.reload()
                }
            })
        }
    }

    const showDataInTable = () => {
        return selectableOptions.map(
            (info, index) => {
                return (
                    <tr key={'row-'+ index}>
                        <td>{index + 1}</td>
                        <td>{info.email}</td>
                        <td>{moment(info.createdAt).format('DD/MM/YYYY hh:mm A')}</td>
                        <td>{info.clients.join(", ")}</td>
                        <td><Link to={"/edit-admin/" + info.id}><button className='list-client-btn-edit'>Edit</button></Link></td>
                        <td><button className='list-client-btn-edit' onClick={() => deleteUser(info.email)}>Delete</button></td>
                    </tr>
                )
            })
    }

    return (
        <div>
            {/* <Header></Header> */}
            <div className="table-info-list-client table-info">
                <table border="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>S. No</th>
                            <th>Email</th>
                            <th>Create Date</th>
                            <th>Clients</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody className='list-client-tbody'>
                        {showDataInTable()}
                    </tbody>
                </table>
            </div>

            <Link to={"/create-admin"}><button className='list-client-btn-add'>+ Add Admin</button></Link>

        </div>
    )

}

export default ListAdmins;