import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ConfigurationStore from '../../stores/ConfigurationStore';
import GlobalStore from '../../stores/GlobalStore';
import {Link} from 'react-router-dom';

function ShopifyAccounts(props) {
    const [shopifyAccounts, setShopifyAccounts] = useState([]);
    useEffect(()=>{
        ConfigurationStore.getShopifyAccounts().then(()=>{
            console.log(`returned from config store: ${JSON.stringify(ConfigurationStore?.shopifyAccounts)}`);
            setShopifyAccounts(ConfigurationStore?.shopifyAccounts)
        });
        GlobalStore.pageName = 'shopify-accounts';
    },[])

    const edit = (id) => {
        props.history.push(`/shopify-account/edit/${id}`);
    }

    const onDelete = (id) => {
       let r = window.confirm('Are you sure to delete this account?');
       if(r){
           ConfigurationStore.deleteShopifyAccount(id).then(()=>{
                ConfigurationStore.getShopifyAccounts()
           })
       }
    }

   // let {shopifyAccounts=[]} = ConfigurationStore;
   // console.log(`shopifyAccounts: ${JSON.stringify(shopifyAccounts)}`)
    return <React.Fragment>
        <div className="table-info">
            <table border="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Name</th>
                       
                        <th>Client</th>
                        
                        <th>Terr Code</th>
                        <th>Terr Desc</th>
                        <th>Sales Code</th>
                        <th>Sales Name</th>
                        <th>Status</th>
                        <th>Amazon Acct</th>
                        <th>Amazon Whs</th>
                        <th>Def CustNo</th>
                        <th>Def Whs</th>
                        <th>Def Curr</th>
                        
                    </tr>
                </thead>
                <tbody>
                {
                    shopifyAccounts && shopifyAccounts.map(account=>{
                        return <tr>
                                    <td>{account.name}</td>
                                    
                                    <td>{account?.client_data[0]?.code}</td>
                                   
                                    <td>{account.territory_code}</td>
                                    <td>{account.territory_desc}</td>
                                    <td>{account.salesperson_code}</td>
                                    <td>{account.salesperson_name}</td>
                                    <td>{account.status}</td>
                                    <td>{account.amazon_cust_no}</td>
                                    <td>{account.amazon_warehouse}</td>
                                    <td>{account.default_cust_no}</td>
                                    <td>{account.default_warehouse}</td>
                                    <td>{account.default_currency}</td>
                                    
                                    <td>
                                        <button className="primarybtn" onClick={()=>edit(account._id)}>Edit</button>
                                        <button disabled={account.in_use} onClick={()=>onDelete(account._id)} className="primarybtn delete-btn">Delete</button>
                                    </td>
                                </tr>
                    })
                }
                    
                </tbody>
            </table>

            <div className="bottom-button">
                <Link to="/shopify-account/add"><button className="primarybtn add-btn">+ Add Account</button></Link>
            </div>
        </div>
    </React.Fragment>
}

export default observer(ShopifyAccounts);
