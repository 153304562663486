import { observer } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";
import GlobalStore from "../../stores/GlobalStore";
import { Link } from "react-router-dom";

function Connections(props) {
  useEffect(() => {
    ConfigurationStore.getConnections();
    GlobalStore.pageName = "connections";
  }, []);

  const onDeact = (id) => {
    let r = window.confirm(
      "Are you sure to deactivate this connection? All configurations using this connection will be deactivated automatically"
    );
    if (r) {
      ConfigurationStore.deactivateConnection(id).then((result) => {
        ConfigurationStore.getConnections();
      });
    }
  };

  const onActivate = (id) => {
    let r = window.confirm(
      "Are you sure to activate this connection? All configurations using this connection will NOT be activated automatically"
    );
    if (r) {
      ConfigurationStore.actiavateConnection(id).then((result) => {
        ConfigurationStore.getConnections();
      });
    }
  };

  const edit = (connection_id) => {
    props.history.push(`/connection/edit/${connection_id}`);
  };

  let { connections = [] } = ConfigurationStore;
  // console.log(`connections: ${JSON.stringify(connections)}`)
  return (
    <div className="table-info">
      <table border="0" cellSpacing="0">
        <thead>
          <tr>
            <th>Connection name</th>
            <th>Spire Account</th>
            <th>Shopify</th>
            <th>Hubspot</th>
            <th>Client code</th>
            <th>Client Name</th>
            <th>Type</th>
            <th>Status</th>
            <th>Last used</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {connections &&
            connections.map((connection) => {
              return (
                <tr>
                  <td>{connection.name}</td>
                  <td>
                    {connection.spire &&
                      connection.spire[0] &&
                      connection.spire[0].name}
                  </td>
                  <td>
                    {connection.shopify &&
                      connection.shopify[0] &&
                      connection.shopify[0].name}
                  </td>
                  <td>
                    {connection.hubspot &&
                      connection.hubspot[0] &&
                      connection.hubspot[0].name}
                  </td>
                  <td>
                    {connection.client_data &&
                      connection.client_data[0] &&
                      connection.client_data[0].code}
                  </td>
                  <td>
                    {connection.client_data &&
                      connection.client_data[0] &&
                      connection.client_data[0].name}
                  </td>
                  <td>
                    <select
                      name="connection_type"
                      className="form-control"
                      disabled="true"
                      value={connection.connection_type}
                    >
                      <option value="">Select type</option>
                      <option value="sp2sh">Spire to Shopify</option>
                      <option value="sp2hs">Spire to Hubspot</option>
                    </select>
                  </td>
                  <td>
                    <span
                      className={`color-text ${
                        connection.status === "active" ? `gr-color` : `rd-color`
                      }`}
                    >
                      {connection.status && connection.status.toUpperCase()}
                    </span>
                  </td>
                  <td>
                    {moment(connection.createdAt).format("DD/MM/YYYY hh:mm A")}
                  </td>
                  <td>
                    <button
                      className="primarybtn"
                      onClick={() => edit(connection._id)}
                    >
                      Edit
                    </button>
                    {connection.status === "active" ? (
                      <button
                        disabled={connection.in_use}
                        onClick={() => onDeact(connection._id)}
                        className="primarybtn delete-btn"
                      >
                        Deactivate
                      </button>
                    ) : (
                      <button
                        className="primarybtn"
                        onClick={() => onActivate(connection._id)}
                      >
                        Activate
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <div className="bottom-button">
        <Link to="/connection/add">
          <button className="primarybtn add-btn">+ Add Connection</button>
        </Link>
      </div>
    </div>
  );
}

export default observer(Connections);
