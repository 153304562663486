import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react'
import ConfigurationStore from '../../stores/ConfigurationStore';
import constants from '../../utils/Constants';
import {Select} from 'antd';
import ReactTags from 'react-tag-autocomplete';
import webhookSubscriptions from '../../assets/json/webhookSubscriptions.json';
import {DatePicker} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import AuthStore from "../../stores/AuthStore";

function Configuration(props) {

    const [type,setType] = useState();
    const [client_id, setClientId] = useState(null);
    const [selectedFields, setSelectedFields] = useState([]);
    const [subscribedWebhooks,setSubscribedWebhooks] = useState([]);
    const [loading,setLoading] = useState(false);
    const [hubspotFields,setHubspotFields] = useState({});
    const form = useRef(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isCronJob, setIsCronJob] = useState(false);
     const [result, setResult] = useState(null);
    const {Option} = Select; 
    const [selectableOptions, setSelectableOptions] = useState([]);
    const [hidden, setHidden] = useState(false);
    const [connection_id,setConnection_id] = useState('');

    useEffect(()=>{
        ConfigurationStore.getConnections();
        ConfigurationStore.getClients();
        if(props.match.params.id){
            setIsEdit(true);
            ConfigurationStore.getConfiguration(props.match.params.id).then(result=>{
                setResult(result);
               // console.log(`config result: ${JSON.stringify(result)}`)
                Object.keys(result).map(key=>{
                    //console.log(`key: ${key}`)
                    if(document.getElementsByName(key).length > 0){
                        document.getElementsByName(key)[0].value = result[key]?result[key]:'';
                    }
                        if(key ==='configType'){
                            setType(result[key])
                        }
                        if(key ==='connection_id'){
                           // console.log(`setting connection: ${result[key]}`)
                            setConnection_id(result['connection_id'])
                        }
                        if(key === 'client_id'){
                            setClientId(result[key])
                         }
                        if(key === 'isEnabled'){
                            document.getElementsByName(key)[0].checked = result['isEnabled'];
                            setIsEnabled(result['isEnabled']);
                        }
                        if(key === 'isCronJob'){
                            document.getElementsByName(key)[0].checked = result['isCronJob'];
                            setIsCronJob(result['isCronJob']);
                        }
                        
                    

                    if(key === 'events'){
                        setSubscribedWebhooks(result['events']);
                        setSelectedFields(result['webhooks']);
                    }

                    if(key === 'configType' && result['configType'] === 'hs2sp'){
                        onChangeConnection(result['connection_id']);
                    }
                })
            })
        }
        
    },[])

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(form.current);
        let data = {events:[]};
        for (var key of formData.keys()) {
            //console.log("key",key)
            if(key.startsWith('events.')){
                if(formData.get(key) === 'on'){
                    data.events.push(key.replace('events.',''))
                }
            }
            else{
                data[key] = formData.get(key);
            } 
        }
        if((data['configType'] && data['configType'] === 'sp2hs') || type === 'sp2hs'
        || (data['configType'] && data['configType'] === 'sp2sh') || type === 'sp2sh'){
            data['isEnabled'] = isEnabled;
            data['isCronJob'] = isCronJob;
        }
        data['webhooks'] = selectedFields;

        if(data['nextSync']){
            let currentTimeDiff = moment(data['nextSync']).diff(moment(),'seconds');
            if(currentTimeDiff < 120){
                alert('Next sync time has to be at least 2 minutes after from now');
                setLoading(false);
                return;
            }
            data['nextSync'] = moment(data.nextSync).toDate();
        }

        if(data['lastSynced']){
            data['lastSynced'] = moment(data.lastSynced).toDate();
        } 

        if(isEdit){
            let {id} = props.match.params;
            delete data.connection_id;
            ConfigurationStore.updateConfiguration(id,data).then(()=>{
                props.history.push('/configurations');
            })
        }
        else{
            ConfigurationStore.addConfiguration(data).then(()=>{
                props.history.push('/configurations');
            })
        }
    }

    const onChangeConnection = value => {
        console.log(`conn val: ${value}`)
        setConnection_id(value)
        ConfigurationStore.getConnection(value).then(result=>{
            let {hubspot_id} = result;
            if(hubspot_id){
                ConfigurationStore.getHubspotFields(hubspot_id).then(result=>{
                    setHubspotFields(result);
                    if(result.defaultWebhooks && Array.isArray(result.defaultWebhooks)){
                        if(!isEdit){
                            setSelectedFields(result.defaultWebhooks);
                        }
                    }
                })
            }
        })
    }

    const updateFields = (type,fields) => {
        let allFields = [...selectedFields];
        allFields = allFields.filter(f=>f && !(f.indexOf(`${type}.`) === 0))
        fields && fields.forEach(field=>{
            if(!allFields.includes(field)){
                allFields.push(field);
            }
        })
        setSelectedFields(allFields);
    }

    const subscribeWebhook = (w,isChecked) => {
        let ws = [...subscribedWebhooks];
        if(!isChecked && ws.includes(w)){
            ws = ws.filter(wq=>wq !== w)
        }
        else if(isChecked && !ws.includes(w)){
            ws.push(w)
        }
        //console.log(ws);
        setSubscribedWebhooks(ws);
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current <= moment().startOf('day');
      }


    let {connections=[], clients} = ConfigurationStore;
    return (
        <div className="add-element">
            <div className="box">
                <h2>{isEdit?'Edit':'Add'} Configuration</h2>
                <div className="form-block">
                    <form ref={form} onSubmit={e=>onSubmit(e)}>
                        <div className="input-box">
                            <label>Configuration Name</label>
                            <input type="text" name="name" className="form-control" />
                        </div>
                        <div className="input-box">
                            <label>Select Type</label>
                            <select disabled={isEdit} name="configType" className="form-control" onChange={e=>setType(e.target.value)}>
                                <option value="">Select type</option>
                                <option value="sp2sh">Spire to Shopify</option>
                                <option value="sp2hs">Spire to Hubspot</option>
                                <option value="hs2sp">Hubsot to Spire</option>
                            </select>
                        </div>
                       
                        {
                             type &&
                             <React.Fragment>
                        <div className="input-box">
                            <label>Select Connection</label>
                            <select onChange={e=>onChangeConnection(e.target.value)} 
                            name="connection_id" value={connection_id}
                             className="form-control"
                             disabled={isEdit}>
                                <option value="">Select Connection</option>
                                {
                                    connections && connections.filter(con => 
                                        (con.connection_type === type) || ((type === 'sp2hs' || type === 'hs2sp') && con.connection_type === 'sp2hs') ).map(c=>{
                                        return <option value={c._id}>{c.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        </React.Fragment>
                        }
                        
                        <div className="input-box">
                        <label>Select Client</label>
                        <select
                          name="client_id"
                          className="form-control"
                          value={client_id}
                          disabled={isEdit}
                        >
                        <option value="">Select client</option>
                            {clients &&
                            clients.map((c) => {
                        return (
                        <option value={c._id}>
                            {c.name}
                        </option>
                        );
                        })}
                        </select>
                    </div>
                        

                        {
                             type === 'sp2sh' &&
                             <React.Fragment>
                                 <div className="input-box">
                                     <label>Select Field type</label>
                                     <select name="fieldType" className="form-control">
                                     {
                                         constants.shopify_fieldTypes.map(i=>{
                                             return <option value={i}>{i}</option>
                                         })
                                     }
                                     </select>
                                 </div>
                                 <div className="input-box">
                                     <label>Select Interval</label>
                                     <select name="syncInterval" className="form-control">
                                     {
                                         constants.intervals.map(i=>{
                                             return <option value={i}>{i.toString()} minute</option>
                                         })
                                     }
                                     </select>
                                 </div>
                                 <div className="input-box">
                                     <label>Start from</label>
                                     <DatePicker placeholder="Select Date &amp; Time" disabled={isEdit} name="lastSynced" showTime  />
                                 </div>
 
                                 <div className="input-box">
                                     <label>Next Sync Date/Time</label>
                                     {
                                         result && result.nextSync && result.nextSync !== ""?
                                         <p style={{color:"#999"}}>{moment(result.nextSync).format('DD-MM-YYYY hh:mm:ss A')}</p>:null
                                     }
                                     <DatePicker 
                                         name="nextSync" 
                                         showTime
                                         disabledDate={disabledDate}
                                         placeholder="Select Date &amp; Time"
                                     />
                                 </div>
 
                                 <div className="input-box">
                                     <label>Enabled</label>
                                     <input onChange={e=>setIsEnabled(e.target.checked)} type="checkbox" name="isEnabled" />
                                 </div>
                                 <div className="input-box">
                                     <label>Cron Job</label>
                                     <input onChange={e=>setIsCronJob(e.target.checked)} type="checkbox" name="isCronJob" />
                                 </div>
                                 
                             </React.Fragment>
                        }
                        {
                            type === 'sp2hs'?
                            <React.Fragment>
                                <div className="input-box">
                                    <label>Select Field type</label>
                                    <select name="fieldType" className="form-control">
                                    {
                                        constants.hs_fieldTypes.map(i=>{
                                            return <option value={i}>{i}</option>
                                        })
                                    }
                                    </select>
                                </div>
                                <div className="input-box">
                                    <label>Select Interval</label>
                                    <select name="syncInterval" className="form-control">
                                    {
                                        constants.intervals.map(i=>{
                                            return <option value={i}>{i.toString()} minute</option>
                                        })
                                    }
                                    </select>
                                </div>
                                <div className="input-box">
                                    <label>Start from</label>
                                    <DatePicker placeholder="Select Date &amp; Time" disabled={isEdit} name="lastSynced" showTime  />
                                </div>

                                <div className="input-box">
                                    <label>Next Sync Date/Time</label>
                                    {
                                        result && result.nextSync && result.nextSync !== ""?
                                        <p style={{color:"#999"}}>{moment(result.nextSync).format('DD-MM-YYYY hh:mm:ss A')}</p>:null
                                    }
                                    <DatePicker 
                                        name="nextSync" 
                                        showTime
                                        disabledDate={disabledDate}
                                        placeholder="Select Date &amp; Time"
                                    />
                                </div>

                                <div className="input-box">
                                    <label>Enabled</label>
                                    <input onChange={e=>setIsEnabled(e.target.checked)} type="checkbox" name="isEnabled" />
                                </div>
				<div className="input-box">
                                    <label>Cron Job</label>
                                    <input onChange={e=>setIsCronJob(e.target.checked)} type="checkbox" name="isCronJob" />
                                </div>
                                                               
                               
                            </React.Fragment>:
                            type === 'hs2sp'?
                            
                            <React.Fragment>
                                <div className="input-box">
                                    <label>Webhook Configuration</label>
                                    <p>Webhooks will be fired on following events</p>
                                    <div className="checkbox-list">
                                    {
                                        webhookSubscriptions.map(w=>{
                                            return  <React.Fragment>
                                                        <div className="form-group">
                                                            <input onChange={e=>subscribeWebhook(w.key,e.target.checked)} checked={subscribedWebhooks.includes(w.key)} type="checkbox" name={`events.${w.key}`} id={`events.${w.key}`} className="input-check" />
                                                            <label className="check-label" htmlFor={`events.${w.key}`}>{w.label}</label>
                                                        </div>
                                                        {
                                                            subscribedWebhooks.includes('contact.propertyChange') && w.key === 'contact.propertyChange'?
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: '100%', marginBottom:"5px" }}
                                                                value={selectedFields.filter(f=>f && f.startsWith('contact.'))}
                                                                onChange={(fields)=>updateFields('contact',fields)}
                                                                optionLabelProp="label"
                                                            >
                                                                {
                                                                    hubspotFields && hubspotFields.contactFields && hubspotFields.contactFields.map(h=>{
                                                                        return <Option value={h.name}>{h.name}</Option>
                                                                    })
                                                                }

                                                            </Select>:null
                                                            // <ReactTags
                                                            //     tags={selectedFields.filter(sf=>sf.id.indexOf('contact.') > -1)}
                                                            //     suggestions={hubspotFields && hubspotFields.contactFields && hubspotFields.contactFields.map(h=>{return {id:`contact.${h.name}`,name:h.name}})}
                                                            //     onAddition={(e)=>addChangeField(e)} 
                                                            // />:null
                                                        }
                                                        {
                                                            subscribedWebhooks.includes('company.propertyChange') && w.key === 'company.propertyChange'?
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: '100%', marginBottom:"5px" }}
                                                                value={selectedFields.filter(f=>f && f.startsWith('company.'))}
                                                                onChange={(fields)=>updateFields('company',fields)}
                                                                optionLabelProp="label"
                                                            >
                                                                {
                                                                    hubspotFields && hubspotFields.companyFields && hubspotFields.companyFields.map(h=>{
                                                                        return <Option value={`company.${h.name}`}>{h.name}</Option>
                                                                    })
                                                                }

                                                            </Select>:null
                                                        }
                                                        {
                                                            subscribedWebhooks.includes('deal.propertyChange') && w.key === 'deal.propertyChange'?
                                                            <Select
                                                                mode="multiple"
                                                                style={{ width: '100%', marginBottom:"5px" }}
                                                            //    onChange={handleChange}
                                                                value={selectedFields.filter(f=>f && f.startsWith('deal.'))}
                                                                onChange={(fields)=>updateFields('deal',fields)}
                                                                optionLabelProp="label"
                                                            >
                                                            {
                                                                hubspotFields && hubspotFields.dealFields && hubspotFields.dealFields.map(h=>{
                                                                    return <Option value={h.name}>{h.name}</Option>
                                                                })
                                                            }
                                                            </Select>:null
                                                        }
                                                    </React.Fragment>
                                        })
                                    }
                                        
                                    </div>
                                </div>
                            </React.Fragment>
                            :null
                        }
                        <div className="btn-box">
                            <button disabled={loading} className="primarybtn">
                            {
                                loading?'Please wait...':
                                isEdit?'Update':'Add'
                            }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="box desc-box">
                <p>Duis gravida vehicula dui, non gravida ex tempus in. Aliquam in diam eu urna varius cursus. Duis ut auctor purus, non consequat nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas lorem nibh.</p>
            </div>
        </div>
    )
}

export default observer(Configuration);
