import React, { useEffect } from 'react'
import AuthStore from '../stores/AuthStore';

function Hubspot() {

    useEffect(() => {
        let code = new URLSearchParams(window.location.search).get('code');
        AuthStore.oauthHubspot(code).then(()=>{
            setTimeout(()=>{
                window.close();
            },2000)
        }).catch(err=>{
            alert("Account couldn't be connected to webhook app");
        })
    }, [])

    return (
        <div>
            <p>Please wait...</p>
        </div>
    )
}

export default Hubspot