const constants = {
    intervals:[1,5,15,30,45,60,120],
    hs_fieldTypes:[
        "customers","invoices","orders","inventory"
    ],
    shopify_fieldTypes:[
        "inventory"
    ]
}

export default constants;