import { observer } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore"
import GlobalStore from "../../stores/GlobalStore";
import {Link} from 'react-router-dom';

function SalesTaxes(props){

    useEffect(()=>{
        ConfigurationStore.getSalesTaxes();
        GlobalStore.pageName = 'salestaxes';
    },[])

    

    const edit = (salestax_id) => {
        props.history.push(`/salestax/edit/${salestax_id}`);
    }

    let {salestaxes=[]} = ConfigurationStore;
    return <div className="table-info">
                <table border="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>Sales Tax name</th>
                            <th>Shopify Account</th>
                            <th>Country</th>
                            <th>Province/State</th>
                            <th>Code</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                    {
                        salestaxes && salestaxes.map(salestax=>{
                            return <tr>
                                        <td>{salestax.name}</td>
                                        <td>{salestax.shopify && salestax.shopify[0] && salestax.shopify[0].name}</td>
                                        <td>{salestax.country}</td>
                                        <td>{salestax.provstate}</td>
                                        <td>{salestax.code}</td>
                                        
                                        <td>
                                        <button className="primarybtn" onClick={()=>edit(salestax._id)}>Edit</button>
                                        
                                        </td>
                                    </tr>
                        })
                    }
                        
                    </tbody>
                </table>

                <div className="bottom-button">
                    <Link to="/salestax/add"><button className="primarybtn add-btn">+ Add Sales Tax</button></Link>
                </div>
            </div>
            
}

export default observer(SalesTaxes);