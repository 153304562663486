import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";
import { SearchOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

const { Panel } = Collapse;

function SalesTax(props) {
  const [loading, setLoading] = useState(false);
  const [shopifyFields, setShopifyFields] = useState({});
  //const [maps, setMaps] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [editMode,setEditMode] = useState(false);
  const form = useRef(null);



  useEffect(()=>{
    ConfigurationStore.getShopifyAccounts();
    //ConfigurationStore.getSpireAccounts();
    let id = props.match.params.id;
    if(id){
        ConfigurationStore.getSalesTax(id).then((result)=>{
            Object.keys(result).map(key=>{
                if(document.getElementsByName(key).length > 0){
                    document.getElementsByName(key)[0].value = result[key]
                }
            })
        })
        setEditMode(true);
    }
  },[])

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(form.current);
    let data = {};
    for (var key of formData.keys()) {
      data[key] = formData.get(key);
    }

  //  data["maps"] = maps;

    if(editMode){
      let salestax_id = props.match.params.id;
     //delete data.shopify_id; 
      ConfigurationStore.updateSalesTax(salestax_id,data)
      .then(() => {
        setLoading(false);
        props.history.push("/salestaxes");
      })
      .catch((err) => {
        setLoading(false);
      });
    }
    else{
      ConfigurationStore.createSalesTax(data)
      .then(() => {
        setLoading(false);
        props.history.push("/salestaxes");
      })
      .catch((err) => {
        setLoading(false);
      });
    }

    
  };

 

  let { shopifyAccounts } = ConfigurationStore;
  return (
    <div className="add-element">
      <div className="box">
        <h2>{editMode?'Edit':'Add'} connection</h2>
        <div className="form-block">
          <form ref={form} onSubmit={(e) =>onSubmit(e)}>
            <div className="input-box">
              <label>Name</label>
              <input className="form-control" name="name" />
            </div>
            
            <div className="input-box">
              <label>Shopify Account</label>
              <select
                disabled={editMode}
                required
                name="shopify_id"
                className="form-control"
              >
                <option value="">Select account</option>
                {shopifyAccounts &&
                  shopifyAccounts.map((a) => {
                    return (
                      <option value={a._id}>
                        {a.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="input-box">
              <label>Country</label>
              <input className="form-control" name="country" />
            </div>

            <div className="input-box">
              <label>Province/State</label>
              <input className="form-control" name="provstate" />
            </div>

            <div className="input-box">
              <label>Tax Code</label>
              <input className="form-control" name="code" />
            </div>
            

            <div className="btn-box">
            <button disabled={loading} htmlType="submit" type="submit" className="primarybtn create-cnt-btn">
                {loading ? `Please wait...` : `${editMode?'Update':'Create'} Sales Tax`}
            </button>
            </div>
          </form>
        </div>
        
      </div>
    </div>
  );
}

export default observer(SalesTax);
