import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";
import AuthStore from "../../stores/AuthStore";



function ShopifyProductCategory(props) {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const form = useRef(null);
  const [selectableOptions, setSelectableOptions] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [client, setClient] = useState(null);
  const [syncToCrm, setSyncToCrm] = useState(false);


  useEffect(() => {
    
    let id = props.match.params.id;
    if (id) {
      ConfigurationStore.getShopifyProductCategory(id).then((result) => {
        Object.keys(result).map(key => {
          if (document.getElementsByName(key).length > 0) {
            document.getElementsByName(key)[0].value = result[key]
          }
         
        })
      })
      setEditMode(true);
    }



    if (localStorage.getItem('userRole') === 'super') {
      setHidden(true)
    }
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(form.current);
    let data = {};
   // let userCompany = localStorage.getItem('userCompany')
    for (var key of formData.keys()) {
      data[key] = formData.get(key);
    }

   
    if (editMode) {
      let productTaxonomyId = props.match.params.id;
      ConfigurationStore.updateShopifyProductCategory(productTaxonomyId, data)
        .then(() => {
          setLoading(false);
          props.history.push("/shopifyproductcategories");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    else {
      ConfigurationStore.createShopifyProductCategory(data)
        .then(() => {
          setLoading(false);
          props.history.push("/shopifyproductcategory");
        })
        .catch((err) => {
          setLoading(false);
        });
    }


  };


  return (
    <div className="add-element">
      <div className="box">
        <h2>{editMode ? 'Edit' : 'Add'} Product Categories</h2>
        <div className="form-block">
          <form ref={form} onSubmit={(e) => onSubmit(e)}>
            <div className="input-box">
              <label>Product Category</label>
              <input className="form-control" name="name" />
            </div>
            <div className="input-box">
              <label>Category ID</label>
              <input className="form-control" name="productTaxonomyId" />
            </div>
           
            <div className="btn-box">
              <button disabled={loading} htmlType="submit" type="submit" className="primarybtn create-cnt-btn">
                {loading ? `Please wait...` : `${editMode ? 'Update' : 'Create'} Shopify Product Category`}
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
}

export default observer(ShopifyProductCategory);
