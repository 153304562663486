import React, { useEffect, useRef, useState } from 'react'
import CompanyStore from '../Create-Admin/CompanyStore';
import { observer } from "mobx-react";
import AuthStore from '../../stores/AuthStore';
import GlobalStore from "../../stores/GlobalStore";

const AddClient = (props) => {

    const form = useRef(null);
    const [editMode, setEditMode] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState([])
    const [isPrdCodeForInvToCrm, setIsPrdCodeForInvToCrm] = useState(false);
    const [price_level, setPriceLevel] = useState('1');
    const [isUdfForShopify, setIsUdfForShopify] = useState(false);
    const [doNotSyncContactsSp2Hs, setDoNotSyncContactsSp2Hs] = useState(false);
    const [syncMappedCustSp2Hs, setSyncMappedCustSp2Hs] = useState(false);

    useEffect(() => {
        GlobalStore.pageName = 'create';
        let id = props.match.params.id
        if (id) {
            setEditMode(true)
            localStorage.setItem('editingCompany', id)
            AuthStore.getClientById(id).then((response) => {
                //console.log('selected Client', response)
                setSelectedCompany(response[0])
                setIsPrdCodeForInvToCrm(response[0].isPrdCodeForInvToCrm)
                setIsUdfForShopify(response[0].isUdfForShopify);
                setDoNotSyncContactsSp2Hs(response[0].doNotSyncContactsSp2Hs);
                setSyncMappedCustSp2Hs(response[0].syncMappedCustSp2Hs);
                if(response[0]?.price_level){
                    setPriceLevel(response[0]?.price_level)
                }
                
            })
        }

    }, [])

    

    const onSubmit = e => {
        e.preventDefault();
        const formData = new FormData(form.current)
        const name = formData.get('clientName')
        let code = formData.get('code')
        const phaseIdForOrderHsSh2Sp = formData.get('phaseIdForOrderHsSh2Sp')
        const orderTypeHsSh2Sp = formData.get('orderTypeHsSh2Sp')
        const required_date_offset = formData.get('required_date_offset')
       
        if (!editMode) {
            CompanyStore.addCompany({ name, code, isPrdCodeForInvToCrm, price_level, isUdfForShopify,
                doNotSyncContactsSp2Hs, syncMappedCustSp2Hs, phaseIdForOrderHsSh2Sp, orderTypeHsSh2Sp, required_date_offset }).then((response) => {
                alert('Created Successfully')
                // window.location.reload();
                window.location.href = '/list-client'
            }).catch(() => {
                alert('Failed to Add')
                window.location.reload();
            })
        } else {
            code = localStorage.getItem('editingCompany')
            CompanyStore.updateCompanyName({ name, code, isPrdCodeForInvToCrm, price_level, isUdfForShopify, 
                doNotSyncContactsSp2Hs, syncMappedCustSp2Hs, phaseIdForOrderHsSh2Sp, orderTypeHsSh2Sp, required_date_offset }).then((response) => {
                alert('Updated Successfully')
                window.location.reload();
            }).catch(() => {
                alert('Failed to update')
                window.location.reload();
            })
        }
    };


    return (
        <React.Fragment>
            <div className='form-outliner'>
                <div className='base-form center'>
                    <h2>{editMode ? 'Edit' : 'Add'} company</h2>
                    <form autocomplete="off" ref={form} onSubmit={e => onSubmit(e)}>
                        <div className="input-box">
                            <label>Name</label>
                            <input name='clientName' className="form-control" type="text" placeholder="company name" defaultValue={editMode ? selectedCompany.name : ''} ></input>
                        </div>
                        <div className="input-box">
                            <lable>Code</lable>
                            <input name='code' className="form-control" type="text" maxLength='3' placeholder="code" disabled={editMode} defaultValue={editMode ? selectedCompany.code : ''}></input>
                        </div>
                        <div className="input-box">
                                    <label>Inventory Sync for specific Product Codes</label>
                                    <input onChange={e=>setIsPrdCodeForInvToCrm(e.target.checked)} type="checkbox" name="isPrdCodeForInvToCrm" value={isPrdCodeForInvToCrm} checked={isPrdCodeForInvToCrm}/>
                                </div>
                            <div className="input-box">
                            <label>Price Level</label>
                            <select name="price_level" className="form-control" onChange={e=>setPriceLevel(e.target.value)} value = {price_level}>
                                <option value="">Select Price Level</option>
                                <option value="1">Level 1</option>
                                <option value="2">Level 2</option>
                                <option value="3">Level 3</option>
                            </select>
                        </div>
                        <div className="input-box">
                                    <label>UDFs Enabled</label>
                                    <input onChange={e=>setIsUdfForShopify(e.target.checked)} type="checkbox" name="isUdfForShopify" value={isUdfForShopify} checked={isUdfForShopify} />
                                </div>
                        <div className="input-box">
                                    <label>Do Not Sync Contacts From Spire to Hubspot</label>
                                    <input onChange={e=>setDoNotSyncContactsSp2Hs(e.target.checked)} type="checkbox" name="doNotSyncContactsSp2Hs" value={doNotSyncContactsSp2Hs} checked={doNotSyncContactsSp2Hs} />
                                </div>
                        <div className="input-box">
                                    <label>Sync Only mapped Customer Sp2Hs</label>
                                    <input onChange={e=>setSyncMappedCustSp2Hs(e.target.checked)} type="checkbox" name="syncMappedCustSp2Hs" value={syncMappedCustSp2Hs} checked={syncMappedCustSp2Hs} />
                                </div>
                        <div className="input-box">
                            <lable>Phase Id for Orders HsSh2Sp</lable>
                            <input name='phaseIdForOrderHsSh2Sp' className="form-control" type="text" placeholder="phaseId"  defaultValue={editMode ? selectedCompany.phaseIdForOrderHsSh2Sp : ''}></input>
                        </div>
                        <div className="input-box">
                            <lable>Order Type for Orders HsSh2Sp</lable>
                            <input name='orderTypeHsSh2Sp' className="form-control" type="text" maxLength='1' placeholder="Order Status"  defaultValue={editMode ? selectedCompany.orderTypeHsSh2Sp : ''}></input>
                        </div>
                        <div className="input-box">
                            <lable>Required Date Offset for Order from Sh2Sp</lable>
                            <input name='required_date_offset' className="form-control" type="text" maxLength='2' placeholder="0" defaultValue={editMode ? selectedCompany.required_date_offset : ''}></input>
                        </div>
                        <div className="btn-field adminpage-btn">
                            <button className="primarybtn">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default observer(AddClient);