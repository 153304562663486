import { useEffect } from "react";

function Logout(props) {
    useEffect(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userCompany')
        localStorage.removeItem('userRole')
        props.history.push('/login');
    }, [])
    return <p>Logging out...</p>
}

export default Logout;