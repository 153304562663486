import { useEffect, useRef, useState } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";


function ShopifyAccount(props){

    const [loading,setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const form = useRef(null);
    const [useOnlyDefaultCustomer, setOnlyDefualtCustomer] = useState(false);
    
    const [client_id, setClientId] = useState(null);
    useEffect(()=>{
        ConfigurationStore.getClients();
        let id = props.match.params.id;
        console.log(`shpfy acct id: ${id}`)
        if(id){
            ConfigurationStore.getShopifyAccount(id).then((result)=>{
                Object.keys(result).map(key=>{
                   
                    if(document.getElementsByName(key).length > 0){
                        document.getElementsByName(key)[0].value = result[key]?result[key]:''

                        if(key === 'use_only_default_customer'){
                            document.getElementsByName(key)[0].checked = result['use_only_default_customer'];
                            setOnlyDefualtCustomer(result['use_only_default_customer']);
                        }
                        if(key === 'client_id'){
                            setClientId(result[key])
                         }
                    }
                })
            })
            setEditMode(true);
        }

       
    },[])

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(form.current);
        let data = {};
        for (var key of formData.keys()) {
            data[key] = formData.get(key);
        }
        data['use_only_default_customer'] = useOnlyDefaultCustomer;
        if(editMode){
            data['client_id'] = client_id;
            ConfigurationStore.updateShopifyAccount(props.match.params.id,data).then(()=>{
                setLoading(false);
                props.history.push('/shopify-accounts');
            }).catch(err=>{
                setLoading(false);
            })
        }
        else{
            ConfigurationStore.createShopifyAccount(data).then(()=>{
                setLoading(false);
                props.history.push('/shopify-accounts');
            }).catch(err=>{
                setLoading(false);
            })
        }
    }

    const { clients } = ConfigurationStore;
    return (
        <div className="add-element">
        <div className="box">
            <h2>{editMode?'Edit':'Add'} Shopify account</h2>
            <div className="form-block">
                <form ref={form} onSubmit={e=>onSubmit(e)}>
                    <div className="input-box">
                        <label>Shopify Store name</label>
                        <input required name="name" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Shopify Domain</label>
                        <input required name="store_domain" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Shopify Secret</label>
                        <input required name="secret" type="text" className="form-control" />
                    </div>
                    
                    <div className="input-box">
                        <label>Shopify Access Token</label>
                        <input required name="shopify_access_token" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Shopify Storefront Access Token</label>
                        <input required name="shopify_storefront_access_token" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Metafield Namespace</label>
                        <input name="metafield_namespace" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Metafield Key</label>
                        <input name="metafield_key" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Territory Code</label>
                        <input name="territory_code" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Territory Description</label>
                        <input name="territory_desc" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Salesperson Code</label>
                        <input name="salesperson_code" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Salesperson Name</label>
                        <input name="salesperson_name" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Amazon Customer No</label>
                        <input name="amazon_cust_no" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Amazon Warehouse</label>
                        <input name="amazon_warehouse" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                                     <label>Use Only Default Customer</label>
                                     <input onChange={e=>setOnlyDefualtCustomer(e.target.checked)} type="checkbox" name="use_only_default_customer" />
                                 </div>
                    <div className="input-box">
                        <label>Default Customer No</label>
                        <input name="default_cust_no" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Default Warehouse</label>
                        <input name="default_warehouse" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Default Currency</label>
                        <input name="default_currency" type="text" className="form-control" />
                    </div>
                    
                    <div className="input-box">
                        <label>Select Client</label>
                        <select
                          disabled={editMode}
                          name="client_id"
                          className="form-control"
                          value={client_id}
                        >
                        <option value="">Select client</option>
                            {clients &&
                            clients.map((c) => {
                        return (
                        <option value={c._id}>
                            {c.name}
                        </option>
                        );
                        })}
                        </select>
                    </div>
                    <div className="btn-box">
                        <button disabled={loading} htmlType="submit" className="primarybtn">
                        {
                            loading?`Please wait...`:editMode?'Update':'Add'
                        }
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div className="box desc-box">
            <p>Duis gravida vehicula dui, non gravida ex tempus in. Aliquam in diam eu urna varius cursus. Duis ut auctor purus, non consequat nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas lorem nibh.</p>
        </div>
    </div>
    )
}

export default ShopifyAccount;