import { observer } from "mobx-react";
import { useEffect } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore"
import GlobalStore from "../../stores/GlobalStore";
import {Link} from 'react-router-dom';

function Warehouses(props){

    useEffect(()=>{
        ConfigurationStore.getWarehouses();
        GlobalStore.pageName = 'warehouses';
    },[])



    const edit = (warehouse_id) => {
        props.history.push(`/warehouse/edit/${warehouse_id}`);
    }

    let {warehouses=[]} = ConfigurationStore;
    console.log(`warehouses: ${JSON.stringify(warehouses)}`)
    return <div className="table-info">
                <table border="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>Warehouse name</th>
                            <th>Warehouse Code</th>
                            <th>Shopify Location ID</th>
                            <th>Client</th>
                            <th>Action</th>
                            
                        </tr>
                    </thead>
		    <tbody>
                    {
                        warehouses && warehouses.map(warehouse=>{
                            return <tr>
                                        <td>{warehouse.name}</td>
                                        <td>{warehouse.whse}</td>
                                        <td>{warehouse.shopify_loc_id}</td>
                                        <td>{warehouse?.client_data[0]?.code}</td>
                                        <td>
                                        <button className="primarybtn" onClick={()=>edit(warehouse._id)}>Edit</button>
                                        </td>
                                        
                                    </tr>
                        })
                    }
                        
                    </tbody>
                    
                </table>

                <div className="bottom-button">
                    <Link to="/warehouse/add"><button className="primarybtn add-btn">+ Add Warehouse</button></Link>
                </div>
            </div>
            
}

export default observer(Warehouses);
