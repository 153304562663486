import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './components/Login/Login';
import Logout from './components/Login/Logout';
import Home from './containers/Home';
import Hubspot from './Redirections/Hubspot';

const ProtectedRoute = (props) =>{
    return <Route path={props.path} component={Home} />
}

function Routes(){
    return  <Router>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/app/hubspot/redir" exact component={Hubspot} />
                    <ProtectedRoute path="/" component={Home} />
                    
                </Switch>
            </Router>
}

export default Routes;