import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";
import { SearchOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import AuthStore from "../../stores/AuthStore";

const { Panel } = Collapse;

function Connection(props) {
  const [loading, setLoading] = useState(false);
  const [hubspotFields, setHubspotFields] = useState({});
  const [shopifyFields, setShopifyFields] = useState({});
  const [maps, setMaps] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [editMode, setEditMode] = useState(false);
  const form = useRef(null);
  const [selectableOptions, setSelectableOptions] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [client_id, setClientId] = useState(null);
  const [type,setType] = useState('');


  useEffect(() => {
    ConfigurationStore.getShopifyAccounts();
    ConfigurationStore.getHubspotAccounts();
    ConfigurationStore.getSpireAccounts();
    ConfigurationStore.getClients();
    let id = props.match.params.id;
    if (id) {
      ConfigurationStore.getConnection(id).then((result) => {
        Object.keys(result).map(key => {
          console.log(`result on connection edit: ${JSON.stringify(result)}`)
          if (document.getElementsByName(key).length > 0) {
            document.getElementsByName(key)[0].value = result[key]
          }
          if(key === 'client_id'){
            setClientId(result[key])
         }
         if(key ==='connection_type'){
          setType(result[key])
      }
        })
      })
      setEditMode(true);
    }

    
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(form.current);
    let data = {};
   // let userCompany = localStorage.getItem('userCompany')
    for (var key of formData.keys()) {
      data[key] = formData.get(key);
    }

   // data["maps"] = maps;
   // if (userCompany !== 'super') {
  //    data['client'] = userCompany
  //}

    if (editMode) {
      let connection_id = props.match.params.id;
      delete data.spire_id;
      delete data.hubspot_id;
      delete data.shopify_id;
      ConfigurationStore.updateConnection(connection_id, data)
        .then(() => {
          setLoading(false);
          props.history.push("/connections");
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    else {
      ConfigurationStore.createConnection(data)
        .then(() => {
          setLoading(false);
          props.history.push("/connections");
        })
        .catch((err) => {
          setLoading(false);
        });
    }


  };

  const getHubspotFields = (value) => {
    if (value && value !== "") {
      ConfigurationStore.getHubspotFields(value).then((res) => {
        setHubspotFields(res);
      });
    }
  };

  const onMapHubspotSpireField = (hsField, spireField) => {
    let allMaps = [...maps];
    let map = allMaps.find((m) => m.hs.toLowerCase() === hsField.toLowerCase());
    if (map) {
      map["spire"] = spireField;
    } else {
      let mapObj = {
        hs: hsField,
        spire: spireField,
      };
      allMaps.push(mapObj);
    }
    setMaps(allMaps);
  };
  
    const onMapShopifySpireField = (shopifyField, spireField) => {
    let allMaps = [...maps];
    let map = allMaps.find((m) => m.shopify.toLowerCase() === shopifyField.toLowerCase());
    if (map) {
      map["spire"] = spireField;
    } else {
      let mapObj = {
        shopify: shopifyField,
        spire: spireField,
      };
      allMaps.push(mapObj);
    }
    setMaps(allMaps);
  };

  const getMappedValue = (field) => {
    let allMaps = [...maps];
    let map = allMaps.find((m) => m.hs.toLowerCase() === field.toLowerCase());
    if (map) {
      return map.spire;
    } else return "";
  };
  //Need to check on below method call
  const getMappedValueShopify = (field) => {
    let allMaps = [...maps];
    let map = allMaps.find((m) => m.shopify.toLowerCase() === field.toLowerCase());
    if (map) {
      return map.spire;
    } else return "";
  };

  let { spireAccounts, hubspotAccounts, shopifyAccounts, clients } = ConfigurationStore;
  return (
    <div className="add-element">
      <div className="box">
        <h2>{editMode ? 'Edit' : 'Add'} connection</h2>
        <div className="form-block">
          <form ref={form} onSubmit={(e) => onSubmit(e)}>
            <div className="input-box">
              <label>Name</label>
              <input className="form-control" name="name" />
            </div>
            <div className="input-box">
              <label>Spire Account</label>
              <select disabled={editMode} required name="spire_id" className="form-control">
                <option value="">Select account</option>
                {spireAccounts &&
                  spireAccounts.map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </select>
            </div>
            <div className="input-box">
                            <label>Select Type</label>
                            <select name="connection_type" className="form-control" onChange={e=>setType(e.target.value)} disabled={editMode}>
                                <option value="">Select type</option>
                                <option value="sp2sh">Spire to Shopify</option>
                                <option value="sp2hs">Spire to Hubspot</option>
                            </select>
                        </div>
	   {type === 'sp2sh' &&  <div className="input-box">
              <label>Shopify Account</label>
              <select
                disabled={editMode}           
                name="shopify_id"
                className="form-control"
              >
                <option value="">Select account</option>
                {shopifyAccounts &&
                  shopifyAccounts.map((a) => {
                    return (
                      <option value={a._id}>
                        {a.name}
                      </option>
                    );
                  })}
              </select>
            </div>
}
         { type === 'sp2hs' &&   <div className="input-box">
              <label>Hubspot Account</label>
              <select
                disabled={editMode}
                onChange={(e) => getHubspotFields(e.target.value)}
                
                name="hubspot_id"
                className="form-control"
              >
                <option value="">Select account</option>
                {hubspotAccounts &&
                  hubspotAccounts.map((a) => {
                    return (
                      <option disabled={!a.isActive} value={a._id}>
                        {a.name}
                      </option>
                    );
                  })}
              </select>
            </div> }

           
           
            
                        <div className="input-box">
                        <label>Select Client</label>
                        <select
                          disabled={editMode}
                          name="client_id"
                          className="form-control"
                          value={client_id}
                        >
                        <option value="">Select client</option>
                            {clients &&
                            clients.map((c) => {
                        return (
                        <option value={c._id}>
                            {c.name}
                        </option>
                        );
                        })}
                        </select>
                    </div>
            <div className="btn-box">
              <button disabled={loading} htmlType="submit" type="submit" className="primarybtn create-cnt-btn">
                {loading ? `Please wait...` : `${editMode ? 'Update' : 'Create'} Connection`}
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
}

export default observer(Connection);
