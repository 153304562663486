import { useEffect, useRef, useState } from "react";
import ConfigurationStore from "../../stores/ConfigurationStore";
import Timezones from '../../assets/json/timezones.json';
import AuthStore from "../../stores/AuthStore";

function SpireAccount(props){

    const [loading,setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const form = useRef(null);
    
    const [client_id, setClientId] = useState(null);

    useEffect(()=>{
        ConfigurationStore.getClients();
        let id = props.match.params.id;
        if(id){
            ConfigurationStore.getSpireAccount(id).then((result)=>{
                Object.keys(result).map(key=>{
                    if(document.getElementsByName(key).length > 0){
                        document.getElementsByName(key)[0].value = result[key]
                    }
                    if(key === 'client_id'){
                        setClientId(result[key])
                     }
                })
            })
            setEditMode(true);
        }

        
    },[])

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(form.current);
       // formData.append('client', localStorage.getItem('userCompany'))
        //let userCompany = localStorage.getItem('userCompany')

        let data = {};
        for (var key of formData.keys()) {
            data[key] = formData.get(key);
        }
       // if (userCompany !== 'super') {
       //     data['client'] = userCompany
      //  }
        if(editMode){
            ConfigurationStore.updateSpireAccount(props.match.params.id,data).then(()=>{
                setLoading(false);
                props.history.push('/spire-accounts');
            }).catch(err=>{
                setLoading(false);
            })
        }
        else{
            ConfigurationStore.addSpireAccount(data).then(()=>{
                setLoading(false);
                props.history.push('/spire-accounts');
            }).catch(err=>{
                setLoading(false);
            })
        }
    }

    const { clients } = ConfigurationStore;
    return (
        <div className="add-element">
        <div className="box">
            <h2>{editMode?'Edit':'Add'} Spire account</h2>
            <div className="form-block">
                <form ref={form} onSubmit={e=>onSubmit(e)}>
                    <div className="input-box">
                        <label>Account name</label>
                        <input required name="name" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Endpoint</label>
                        <input required name="endpoint" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>DB Name</label>
                        <input required name="dbName" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Timezone</label>
                        <select name="timezone" className="form-control">
                            <option value="">Select one</option>
                            {
                                Timezones && Timezones.map(tz=>{
                                    return <option value={tz.value}>{tz.text}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="input-box">
                        <label>Username</label>
                        <input required name="username" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Password</label>
                        <input required name="password" type="password" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Def Warehouse</label>
                        <input required name="warehouse" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Salesperson Code</label>
                        <input required name="salesperson_code" type="text" className="form-control" />
                    </div>
                    <div className="input-box">
                        <label>Salesperson Name</label>
                        <input required name="salesperson_name" type="text" className="form-control" />
                    </div>

                    
                        <div className="input-box">
                        <label>Select Client</label>
                        <select
                          disabled={editMode}
                          name="client_id"
                          className="form-control"
                          value={client_id}
                        >
                        <option value="">Select client</option>
                            {clients &&
                            clients.map((c) => {
                        return (
                        <option value={c._id}>
                            {c.name}
                        </option>
                        );
                        })}
                        </select>
                    </div>
                    <div className="btn-box">
                        <button disabled={loading} htmlType="submit" className="primarybtn">
                        {
                            loading?`Please wait...`:editMode?'Update':'Add'
                        }
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div className="box desc-box">
            <p>Duis gravida vehicula dui, non gravida ex tempus in. Aliquam in diam eu urna varius cursus. Duis ut auctor purus, non consequat nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas lorem nibh.</p>
        </div>

        <div className="bottom-button">
            <button className="primarybtn add-btn">+ Add Account</button>
        </div>
    </div>
    )
}

export default SpireAccount;