import { makeObservable, observable } from "mobx";
import { http } from "../services/http";

class AuthStore {

    userInf = {};
    userEmail = localStorage.getItem('userEmail') || "";

    doLogin = (values) => new Promise((resolve, reject) => {
        http.post(`/v1/auth/login`, { ...values }).then(result => {
            localStorage.setItem('token', result.data.token);
            localStorage.setItem('userEmail', values.email);
            localStorage.setItem('userCompany', values.companies[0])
            localStorage.setItem('userRole', result.data.user.role)
            this.userEmail = values.email;
            resolve();
        }).catch(err => {
            reject(err.response.data || "Error Occured")
        })
    })

    checkEmail = (values) => new Promise((resolve, reject) => {
        http.post(`/v1/auth/check/email`, { ...values }).then(result => {
            this.userEmail = values.email;
            localStorage.setItem('userEmail', values.email)
            resolve(result.data.clients);
        }).catch(err => {
            reject(err.response.data || "Error Occured")
        })
    })

    getAllClients = () => new Promise((resolve, reject) => {
        http.get(`/v1/admin/companies/all`).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject();
        })
    })

    getAllUsers = () => new Promise((resolve, reject) => {
        http.get(`/v1/admin/users/all`).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject();
        })
    })

    getUserById = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/users/id/${id}`).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject();
        })
    })

    deleteUser = (email) => new Promise((resolve, reject) => {
        http.post(`/v1/admin/users/delete`, {...email}).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject(err.response.data || "Error Occured")
        })
    })

    getClientById = (id) => new Promise((resolve, reject) => {
        http.get(`/v1/admin/companies/get/${id}`).then(result => {
            resolve(result.data);
        }).catch(err => {
            reject();
        })
    })

    checkLoginStatus = () => new Promise((resolve, reject) => {
        http.post(`/v1/auth/status`).then(() => {
            resolve()
        }).catch(result => {
            reject();
        })
    })

    oauthHubspot = (code) => new Promise((resolve, reject) => {
        http.post(`/v1/auth/hubspot/account/verify`, { code }).then(result => {
            resolve();
        }).catch(() => {
            reject();
        })
    })

    constructor() {
        makeObservable(this, {
            userInf: observable
        })
    }
}

export default new AuthStore();